exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-historia-js": () => import("./../../../src/pages/historia.js" /* webpackChunkName: "component---src-pages-historia-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-product-page-js": () => import("./../../../src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-sortiment-js": () => import("./../../../src/templates/sortiment.js" /* webpackChunkName: "component---src-templates-sortiment-js" */)
}

