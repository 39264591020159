module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Hägges","short_name":"Hägges","start_url":"/","background_color":"#efefef","theme_color":"#3C123D","display":"standalone","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"92cb874600e3a3cb511b5da080f86ea9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-asset-path/gatsby-browser.js'),
      options: {"plugins":[],"fileTypes":["js","css","map","png"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://hagges.se"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":8,"matomoUrl":"https://matomo.accodeing.com","siteUrl":"https://hagges.se"},
    },{
      plugin: require('../node_modules/@accodeing/gatsby-theme-heimr/gatsby-browser.js'),
      options: {"plugins":[],"manifest":{"name":"Hägges","short_name":"Hägges","background_color":"#efefef","theme_color":"#3C123D"},"siteUrl":"https://hagges.se","matomoSiteId":8},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Hem","exclude":["/404","/404.html"],"useClassNames":true,"crumbLabelUpdates":[{"pathname":"/sortiment","crumbLabel":"Sortiment"},{"pathname":"/sortiment/hagges-klassiker","crumbLabel":"Hägges Klassiker"},{"pathname":"/sortiment/hagges-premium","crumbLabel":"Hägges Premium"},{"pathname":"/sortiment/mjuka-runda-kakor","crumbLabel":"Mjuka runda kakor"},{"pathname":"/sortiment/muffins","crumbLabel":"Muffins"},{"pathname":"/sortiment/rulltartor","crumbLabel":"Rulltårtor"},{"pathname":"/sortiment/sasongsprodukter","crumbLabel":"Säsongsprodukter"},{"pathname":"/sortiment/tartbotten","crumbLabel":"Tårtbotten"}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
